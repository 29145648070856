// Mix bootswatch theme variables with bootstrap: https://bootswatch.com/help/
@import '~bootswatch/dist/darkly/variables';

// Extend bootstrap utilities: https://getbootstrap.com/docs/5.2/utilities/api/#using-the-api
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

$utilities: map-merge(
  $utilities,
  (
	  //Existing utilities
    "top": map-merge(
      map-get($utilities, "top"),
      (
        values: map-merge(
          map-get(map-get($utilities, "top"), "values"),
          (5: 5%),
        ),
      ),
    ),
    "end": map-merge(
      map-get($utilities, "end"),
      (
        values: map-merge(
          map-get(map-get($utilities, "end"), "values"),
          (5: 5%),
        ),
      ),
    ),

    //New utilities
    "z-index": (
      property: z-index,
      class: z,
      values: (
        10: 10,
        1100: 1100,
      ),
    ),
  )
);
@import '~bootstrap/scss/bootstrap';

// Mix bootswatch main theme with bootstrap: https://bootswatch.com/help/
@import '~bootswatch/dist/darkly/bootswatch';

@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');

html, body {
	height: 100%;
}

body {
	font-family: Inter !important;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}

.sign-in-container{
	height: 100%;
	display: flex;
	align-items: center;
}

.form-signin {
	max-width: 330px;
	padding: 15px;
  }
  
.form-signin .form-floating:focus-within {
z-index: 2;
}

.form-signin input[type="email"] {
margin-bottom: -1px;
border-bottom-right-radius: 0;
border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
margin-bottom: 10px;
border-top-left-radius: 0;
border-top-right-radius: 0;
}

.nav-item:hover {
	transform: translateY(-1px) !important;;
}

hr {
	border-color: $secondary;
}

//help modals
.modal-content {
    background-color: #222222 !important;
}
.tab-content{
	padding-top: 5px !important;
}

a {
	font-weight: bold !important;
}

td.dt-collapse {
	max-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.notifications {
	position: fixed;
	right: 5%;
	top: 5%;
	z-index: 1051;
	display: flex;
	flex-flow: column nowrap;
}
.navbar-nav .nav-link {
    padding-right: 10 !important;
    padding-left: 10 !important;
}
.quest_hide{
	display: none;
}
.home_hide{
	display: none;
}
.doc_hide{
	display: none;
}
.cal_hide{
	display: none;
}
.payer_hide{
	display: none;
}
.prov_hide{
	display: none;
}
.staff_hide{
	display: none;
}
.memb_hide{
	display: none;
}
.qcont_hide{
	display: none;
}
.file{
	padding:5px;
}
.insert_container{
	margin-top: 10px;
}

.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

@media (min-width: 768px) {
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
}

.b-example-divider {
	height: 3rem;
	background-color: rgba(0, 0, 0, .1);
	border: solid rgba(0, 0, 0, .15);
	border-width: 1px 0;
	box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
	flex-shrink: 0;
	width: 1.5rem;
	height: 100vh;
}

.bi {
	vertical-align: -.125em;
	fill: currentColor;
}

.nav-scroller {
	position: relative;
	z-index: 2;
	height: 2.75rem;
	overflow-y: hidden;
}

.nav-scroller .nav {
	display: flex;
	flex-wrap: nowrap;
	padding-bottom: 1rem;
	margin-top: -1px;
	overflow-x: auto;
	text-align: center;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}

.dropdown-toggle { outline: 0; }

.btn-toggle {
  padding: .25rem .5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, .85);
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.scrollarea {
  overflow-y: auto;
}

@import '~datatables.net-bs5';
@import '~datatables.net-buttons-bs5';
